const HalloweenBanner = () => {
  return (
    <div className="flex flex-col-reverse justify-start items-center lg:items-start mx-auto lg:px-12 lg:py-12">
      <div className="lg:hidden">
        <img
          className="p-0 object-cover object-center w-full lg:hidden"
          src="/images/halloween_mobile.png"
          alt="Three slices of different cars completing each other"
          width={866}
          height={410}
        />
      </div>
      <div className="hidden lg:block">
        <img
          className="p-0 object-cover object-center w-full"
          src="/images/halloween_desktop.png"
          alt="Three slices of different cars completing each other"
          width={866}
          height={612}
        />
      </div>
    </div>
  );
};

export default HalloweenBanner;
